import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Avatar from "avataaars"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/styles/BlogCard"

const PostSection = styled.section`
  padding: 1em 0;
`

const PostRows = styled.div`
  display: flex;
  overflow: scroll;
  padding: 20px 0;
  .post {
    margin-right: 1em;
  }

  @media (max-width: 768px) {
    .post {
      margin-right: 0;
    }
  }
`

const Welcome = styled.div`
  display: flex;
  min-height: 400px;
  padding: 30px;
  align-items: center;

  .image-wrap {
    padding-left: 20%;
  }

  .text-wrap {
    width: 100%;
    padding: 20px;
  }

  h1 {
    padding: 0 1em;
    font-family: "Libre Franklin";
    font-weight: 900;
    padding: 0;
  }

  a {
    font-size: 0.6em;
    border-bottom: 2px solid #303030;
  }
  p {
    font-family: "Libre Franklin";
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .image-wrap {
      padding: 0;
    }
  }
`

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  h1 {
    padding: 0 1em;
    font-family: "Libre Franklin";
    font-weight: 900;
    padding: 0;
  }
`

const Section = styled.section`
  margin: 2em 0.5em;
  a {
    color: #303030;
    line-height: 1.5em;
    font-size: 0.5em;
    border-bottom: 2px solid #303030;
    padding: 2px 4px;
  }
`
const IndexPage = () => {
  const [eye, setEye] = useState("Default")
  const [mouth, setMouth] = useState("Default")
  const blogPosts = useStaticQuery(
    graphql`
      query BlogPostQueries {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "blog" } } }
          limit: 3
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                tags
              }
              excerpt(pruneLength: 200)
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <Welcome>
          <div
            className="image-wrap"
            onMouseEnter={() => setEye("Wink")}
            onMouseLeave={() => setEye("Default")}
          >
            <Avatar
              style={{ width: "200px", height: "200px" }}
              avatarStyle="Circle"
              topType="ShortHairShortFlat"
              accessoriesType="Blank"
              hairColor="Black"
              facialHairType="Blank"
              clotheType="Hoodie"
              clotheColor="Heather"
              eyeType={eye}
              eyebrowType="DefaultNatural"
              mouthType={mouth}
              skinColor="Light"
            />
          </div>
          <div
            className="text-wrap"
            onMouseEnter={() => setEye("Wink")}
            onMouseLeave={() => setEye("Default")}
          >
            <h1>Hello,</h1>
            <p>
              Hey there, friend! I'm Lucas Vilela, a fullstack developer
              residing in the beautiful city of Sydney, Australia. My passions
              include crafting great design and embracing the wonders of
              JavaScript. Welcome!
            </p>
            <Link
              to="/about"
              onMouseEnter={() => setEye("Hearts")}
              onMouseLeave={() => setEye("Default")}
            >
              More about me
            </Link>
            <br />
            <Link
              to="/work"
              onMouseEnter={() => setMouth("Smile")}
              onMouseLeave={() => setMouth("Default")}
            >
              Resume
            </Link>
            <br />
            <Link
              to="/blog"
              onMouseEnter={() => setMouth("Smile")}
              onMouseLeave={() => setMouth("Default")}
            >
              Some posts I wrote
            </Link>
          </div>
        </Welcome>
        {/* TODO: Make this section better */}
        <PostSection>
          <PostRows>
            {blogPosts.allMarkdownRemark.edges.map(({ node }, index) => {
              return (
                <div className="post">
                  <BlogCard
                    key={index}
                    title={node.frontmatter.title}
                    excerpt={node.excerpt}
                    link={`blog${node.fields.slug}`}
                    tags={node.frontmatter.tags}
                  ></BlogCard>
                </div>
              )
            })}
          </PostRows>
        </PostSection>

        <Section>
          <h1>Other</h1>
          <Link to="/uses">/uses : Things I use and recommend</Link>
          <br />
          <Link to="/amazing">/amazing : Sites that are worth noticing</Link>
          <br />
          <Link to="/contact">/contact : A form to contact me</Link>
        </Section>
      </Container>
    </Layout>
  )
}

export default IndexPage
