import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import TitleTag from "./TitleTag"

const Card = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #cbd5e0;
  margin-bottom: 0.5em;
  padding: 0.5em;
  transition: 1s all;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 300px;
  box-shadow: 0 10px 0 -5px #91d8f7, 0 20px 0 -10px #ef4b53,
    0 30px 0 -16px #dedcb9;

  :hover {
    transform: skew(0, 0.5deg);
  }

  h2 {
    font-size: 0.6em;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "Libre Franklin";
  }
  p {
    font-size: 0.4em;
    line-height: 20px;
    font-family: "Bitter";
  }

  @media (max-width: 768px) {
    margin: 0.5em;
  }
`

const ReadMore = styled.div`
  padding: 0.5rem 1rem;
  background: #91d8f7;
  transform: rotate(-3deg);
  font-size: 0.6em;
  width: fit-content;
  position: relative;

  :hover {
    background-color: #ef4b53;
  }
  a {
    color: #303030;

    :hover {
      background-color: transparent;
    }
  }
`

const TagFlag = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  position: relative;
  bottom: 140px;
  left: 101%;

  @media (max-width: 768px) {
    display: none;
  }
`

const BlogCard = ({ title, excerpt, link, tags, showTag }) => {
  return (
    <Card className="card">
      <h2>{title}</h2>
      <p>{excerpt}</p>
      {showTag && (
        <TagFlag>
          {tags && tags.map(title => <TitleTag title={title} />)}
        </TagFlag>
      )}
      <ReadMore>
        <Link to={link}>Read more</Link>
      </ReadMore>
    </Card>
  )
}

BlogCard.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.string,
  showTag: PropTypes.bool,
}

export default BlogCard
