import React from "react"
import styled from "styled-components"

const colors = {
  javascript: "#F0DB4F",
  node: "#3C873A",
}

const Tag = styled.div`
  padding: 0.5rem 1rem;
  background: ${props => colors[props.title] || "#f4a261"};
  transform: skew(0, -7deg);
  font-size: 0.6em;
  width: fit-content;
  margin-bottom: 1em;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: 1s all;

  :hover {
    transform: skew(0, -12deg);
    box-shadow: 10px 30px 30px -10px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`

const TitleTag = ({ title, noPadding }) => {
  return (
    <Tag title={title.toLowerCase()} noPadding={noPadding}>
      {title}
    </Tag>
  )
}

export default TitleTag
